import React from 'react';
import { Link } from "react-scroll";
import ffIcon from './assets/floatingfree-icon.png';
import smIcon from './assets/space-mayhem-capsule.jpg';
import downArrow from './assets/down_arrow.png'
import steamIcon from './assets/steam.png';
import gplayIcon from './assets/gplay.png';
import './Games.css';

function FormatGame(game) {
    return (
        <div className="game-wrapper">
            <a href={game.store_link} target="_blank" rel="noopener noreferrer">
                <div className="game-widget">
                    <div className="header">
                        <h1 className="header-name" ref={game.store_link}>Get {game.title} </h1>
                        <h1 className="header-tail">&nbsp;on {game.platform}</h1>
                    </div>
                    <div className="text_area">
                        <img alt="" className="capsule" src={game.ico_src} ref={game.store_link} />
                        <p className="desc"> {game.desc}</p>
                    </div>
                    <div className="purchase_area">
                        <img alt={game.link_alt} ref={game.store_link} src={game.store_icon} />
                    </div>
                </div>
            </a>
        </div>
    );
}

const SpaceMayhem = {
    title: "Space Mayhem",
    platform: "Steam",
    desc: "Inspired by classic arcade shooters, Space Mayhem is a fast-paced score chaser with a retro style. Collect powerups, manage your ammo, obtain massive score multipliers, and blast your way through infinite, chaotic waves of enemies!",
    ico_src: smIcon,
    link_alt: 'Buy it on Steam',
    store_link: 'https://store.steampowered.com/app/922470/Space_Mayhem/',
    store_icon: steamIcon
}

const FloatingFree = {
    title: "Floating Free",
    platform: "Google Play",
    desc: "A simple, relaxing single button score chaser available on android devices",
    ico_src: ffIcon,
    link_alt: 'Get it on Google Play',
    store_link: 'https://play.google.com/store/apps/details?id=com.chronicvagrant.floatingfree&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    store_icon: gplayIcon
}

class Games extends React.Component {
    render() {
        return (
            <section id="games">
                <h1 className="section-header">Games</h1>
                {/* <iframe className="steam-game" src="https://store.steampowered.com/widget/922470/" ></iframe> */}
                {/* <div className="games-wrapper"> */}
                <ul className="game-list">
                    <li>{FormatGame(SpaceMayhem)}</li>
                    <li>{FormatGame(FloatingFree)}</li>
                </ul>
                {/* </div> */}
                <Link className="down-arrow" to="about" spy={true} smooth={true} duration={800}>
                    <img src={downArrow} alt="" />
                </Link>
            </section>
        );
    }
}

export default Games;