import React from 'react';
import { Link } from "react-scroll";
import downArrow from './assets/down_arrow.png';
import ytIcon from './assets/youtube-icon.png';
import twIcon from './assets/twitter-icon.png';
import './About.css';

class About extends React.Component {
    render() {
        return (
            <section id="about">
                <h1 className="section-header">About</h1>
                <div className="about-wrapper">
                    <h2>The Team</h2>
                    <p>
                        <em>Chronic Vagrant</em> is a pretty small indie studio based in
                        California. The team is made up of me working part time to make games
                        that I (and maybe even a few others) will enjoy.
                    </p>

                    <h2>Contact</h2>
                    <p><a href="mailto:chronicvagrant@gmail.com">Send me an email!</a></p>

                    <h2>Links</h2>
                    <a href="https://www.youtube.com/channel/UCR3hbU3yacaPS8RInBdkGIQ"><img className="social-icon" src={ytIcon} alt=""/></a>
                        
                    <a href="https://twitter.com/Chronic_Vagrant"><img className="social-icon" src={twIcon} alt=""/></a>
                </div>
                <Link className="down-arrow flipped" to="logo" spy={true} smooth={true} duration={800}>
                    <img src={downArrow} alt="" />
                </Link>
            </section>
        );
    }
}

export default About;