import React from 'react';
import { useEffect } from 'react';
import {
    Switch,
    Route,
    Link,
    useParams,
} from "react-router-dom"
import './Play.css';

var games = {
    "home": {
        title: "Choose a Game",
        desc: 'Choose a game',
        path: '/games/play/index.js'
    },
    "snek": {
        title: "Snek",
        desc: 'Use the arrow keys to control the snek. Pick up food to grow and avoid hitting your tail',
        path: '/games/snek/index.js'
    },
}

const useScript = url => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.type = "module";

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

function AttachScript() {
    let { index } = useParams();
    useScript((games[index].path));
    return (
        // <React.Fragment />
        <div className="gameDesc">
            {/* <h1> {games[index].title} </h1> */}
            <p> {games[index].desc}</p>
        </div>
    )
}

function GameArea() {
    return (
        <div id="moniter">
            <div id="crt">
                <canvas id="gameCanvas" width="640" height="480" />
            </div>
        </div>
    );
}

function reloadPage() {
    window.location.reload();
}

export default class Play extends React.Component {
    render() {
        return (
            <section id="play">
                <ul className="gameSelection">
                    <li onClick={reloadPage}><Link to="/play/home"><p>Games</p></Link></li>
                    <li onClick={reloadPage}><Link to="/play/snek"><p> &#62; Snek</p></Link></li>
                </ul>
                <GameArea />
                <Switch>
                    <Route exact path="/play" />
                    <Route exact path="/play/:index" component={AttachScript} />
                </Switch>
            </section>
        );
    }
}