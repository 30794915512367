import React from 'react';
import { Link } from "react-scroll";
import cvLogo from './assets/logo.png';
import downArrow from './assets/down_arrow.png'
import './Logo.css';

class Logo extends React.Component {
  render() {
    return (
      <section id="logo">
        <header className="logo-header">
          <img alt="" src={cvLogo} className="main-logo" />
          <Link className="down-arrow" to="games" spy={true} smooth={true} duration={800}>
            <img src={downArrow} alt="" />
          </Link>
        </header>
      </section>
    );
  }
}

export default Logo;
