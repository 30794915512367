import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"
import './Navigation.css';
import playImg from './assets/PlayButton.png';
import homeImg from './assets/HomeButton.png';
import Logo from './Logo';
import Games from './Games';
import About from './About';
import Play from './Play';

function MainPage() {
    return (
        <React.Fragment>
            <Logo />
            <Games />
            <About />
            <PlayButton />
        </React.Fragment>
    );
}

function PlayPage() {
    return (
        <React.Fragment>
            <Play />
            <MainPageButton />
        </React.Fragment>
    );
}

function MainPageButton() {
    return (
        <Link to='/'>
            <div className="nav-button">
                <img src={homeImg} alt="Home" />
            </div>
        </Link>
    );
}

function PlayButton() {
    return (
        <Link to='/play'>
            <div className="nav-button">
                <img src={playImg} alt="Play some games!" />
            </div>
        </Link>
    );
}

class Navigation extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path='/' component={MainPage} />
                    <Route path="/play" component={PlayPage} />
                </Switch>
            </Router>
        );
    }
}

export default Navigation;